import React, {useState} from 'react';
import {Box, Button, Card, Grid, Typography, useMediaQuery} from "@mui/material";
import {TaskAlt} from "@mui/icons-material";
import {words} from "../words";
import apiUrls from "../apiUrls";

const CvAppTemplateCard = ({template, selectTemplate, selectedTemplate}) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [cardHover, setCardHover] = useState(null)
    // const [template, setTemplate] = useState({
    //     id: "",
    //     productType: "CV_TEMPLATE",
    //     lang: "az",
    //     currency: "azn",
    //     name: "Deep web hello purple",
    //     title: "Numune-01 adli numune shablon",
    //     description: "Numune-01 adli numune shablonun aciqlamasi burada olacaq barede",
    //     previewUrl: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png",
    //     filePath: "",
    //     createDate: "",
    //     badge: "",
    //     price: "20",
    //     discount: "20",
    //     specs: "",
    //     status: "",
    // })

    const priceString = () => {
        if (template.price==="0" || template.discount === "" || template.discount === null) {
            if(template.price==="0"){
                return "<span>"+words.cvApp.cvAppForms.free+"</span>"
            }
            else{
               return "<span>"+template.price + " " + template.currency+"</span>"
            }

        } else {
            let temp = template.price - (template.price / 100 * template.discount);
            temp=temp.toFixed(2)
           return  "<div><span style='text-decoration: line-through; font-size: 13px;'>"+template.price + " " + template.currency+"</span><br/> " +
            " "+temp + " " + template.currency+"</div>"
        }
    }

    return (
        <Grid item xs={12} style={{margin: 20}} className={"vacancyCard"}>
            <Grid container justifyContent={"center"} style={{
                padding: 20,
                borderRadius: 10,
                backgroundColor: selectedTemplate === template.id?"#ffe1e3":"#f0e9ff",
                boxShadow: " 0 2px 4px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)"
            }}>
                <Grid item xs={6} m={1}>
                    <Typography style={{color:"#000"}}>{template.name}</Typography>
                    <Typography fontSize={10} style={{color:"#7a7a7a"}}>{template.id}</Typography>
                </Grid>
                <Grid item xs={4} m={1}>
                    <Box style={{backgroundColor:"#4C3198FF",borderRadius:10}} p={.3}>
                        <Typography color={"white"} align={"center"} dangerouslySetInnerHTML={{ __html: priceString() }}>

                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"center"}>
                        <Card
                            onMouseEnter={() => {
                                setCardHover(true)
                            }}
                            onTouchStart={() => {
                                setCardHover(true)
                            }}
                            onTouchEnd={() => {
                                setCardHover(false)
                            }}
                            onMouseLeave={() => {
                                setCardHover(false)
                            }}
                            style={{
                                padding: 10,
                                width: 595 / 2,
                                height: 842 / 2,
                                backgroundSize: "contain",
                                backgroundRepeat:"no-repeat",
                                backgroundPosition: "center",
                                backgroundImage: "url('https://bakujobs.az" + apiUrls.cvAppGetProductPreview.replace("{productId}", template.id)  + "')",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"flex-end",
                            }}>

                            <Button
                                // opacity: cardHover || selectedTemplate === template.id ? "1" : "0", transition: 'all .4s',
                                style={{bottom:"15%"}}
                                disabled={selectedTemplate === template.id}
                                className={selectedTemplate === template.id?"addButton cleanButton":"addButton"}
                                onClick={() => {
                                    selectTemplate(template.id)
                                }}><TaskAlt style={{marginRight:"5px"}}/>{selectedTemplate === template.id ? words.cvApp.cvAppForms.chosen : words.cvApp.cvAppForms.chose}</Button>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CvAppTemplateCard;
