import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {applyMiddleware, createStore} from 'redux'
import rootReducer from "./reducers/rootReducer";
import {BrowserRouter} from "react-router-dom";
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import {logger} from "redux-logger/src";


// import thunk from "redux-thunk";
const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
});
// const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
//     applyMiddleware(logger),
//     // other store enhancers if any
// ));

const store = createStore(rootReducer)
// , applyMiddleware(thunk)
window.localStorage.showCVButton=false

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <React.StrictMode>
                    <App/>
            </React.StrictMode>
        </BrowserRouter>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
