import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as staticVars from "../../utils/staticVars";

const CvAppFAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const accordionItems = [
        { title: 'CV necə hazırlamalıyam?', content: 'CV hazırlamaq üçün təcrübə, təhsil və bacarıqlarınızı dəqiq və qısa şəkildə qeyd edin.' },
        { title: 'CV-yə nə daxil etməliyəm?', content: 'Ad, əlaqə məlumatları, iş təcrübəsi, təhsil, bacarıqlar və istinadlar daxildir.' },
        { title: 'CV-nin uzunluğu neçə səhifə olmalıdır?', content: 'İdeal olaraq, CV bir səhifə olmalıdır. Lakin çoxlu təcrübəniz varsa, iki səhifəyə qədər ola bilər.' },
        { title: 'CV-də fotoşəkil istifadə etmək lazımdırmı?', content: 'Bu, iş elanının tələblərindən asılıdır. Fotoşəkil tələb olunursa, professional bir şəkil əlavə edin.' },
        { title: 'CV-ni hansı formatda göndərməliyəm?', content: 'Ən çox PDF formatı istifadə olunur, çünki bu format hər cihazda eyni görünür.' },

        // { title: 'Section 5', content: 'Content for Section 5' },
    ];

    return (
        <Box sx={{ width: '100%', maxWidth: 600, margin: 0, mb:2,  boxShadow: "0px 0 3px rgba(0, 0, 0, 0.3)", }}>
            {accordionItems.map((item, index) => {
                const panel = `panel${index + 1}`;
                return (
                    <Accordion
                        key={panel}
                        expanded={expanded === panel}
                        onChange={handleChange(panel)}
                        sx={{
                            '&.Mui-expanded': {
                                margin: 0,
                                marginTop:'1px',
                                // boxShadow: "0px 0 5px rgba(0, 0, 0, 0.5)",
                                // marginBottom:'1px',
                                // backgroundColor: staticVars.colors.bgPurple
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${panel}-content`}
                            id={`${panel}-header`}
                            style={{backgroundColor:staticVars.colors.bgPurple}}
                        >
                            <Typography variant={"button"} style={{color:staticVars.colors.darkPurple}} >{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{backgroundColor:staticVars.colors.bgPurple}}
                        >
                            <Typography variant={"caption"}>{item.content}</Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

export default CvAppFAQ;
