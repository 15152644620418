import React from 'react';
import './App.css';
import Main from "./pages/main";
import {createTheme, ThemeProvider} from "@mui/material";
import {hot} from "react-hot-loader/root";
import PullToRefresh from "react-simple-pull-to-refresh";


// const defaultTheme = createTheme();
const theme = createTheme({
    palette: {
        primary: {
            main: '#1778f2',
        },
        secondary: {
            main: '#e50914'
        },
        success: {
            main:'#07C04D'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        }}
});
const App = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <div id={"root"} className="App">
                {/*<PullToRefresh onRefresh={()=>{window.location.reload()}} pullingContent={"Yeniləmək üçün çəkin..."}>*/}
                <Main/>
                {/*</PullToRefresh>*/}
            </div>
        </ThemeProvider>

    );
}
// export default App;
export default hot(App);

